import axios from 'axios'
import { Cookie, EventBus, signOut } from './assets/js/helpers'
import Auth from './assets/js/auth'
import { store } from '@/assets/js/store'

export const apiUrl = (window.location.host.indexOf('dev.portal') > -1 ||  window.location.host.indexOf('localhost') > -1) ? 'https://dev.api.lassox.com:4444' : 'https://' + window.location.host.replace('portal', 'api')
// if (process.env.NODE_ENV === 'production') {
//   window.token = Cookie.get('apiToken') ?? undefined
//   window.setInterval(() => {
//     if (!store.state.showingSignedInElsewhereDialogue && window.token !== Cookie.get('apiToken')) {
//       const oldToken = window.token

//       window.token = Cookie.get('apiToken') ?? undefined

//       EventBus.$emit('token-changed', { oldToken: oldToken, newToken: window.token })
//     }
//   }, 1000)
// } else {
//   window.token = process.env.VITE_APP_TOKEN
// }
// const token = Auth.getToken()


axios.defaults.baseURL = apiUrl

axios.interceptors.request.use(
  (config) => {
    let token = Auth.getToken()
    if (!token) throw new Error('Token not found, cannot make request')
    config.headers.Authorization = 'Bearer ' + token
    return config
  }, 
  (error) => Promise.reject(error)
)

axios.interceptors.response.use((response) => {
  if (response && response.config.url) {
    if (response.status === 401 && response.config.url.indexOf('login') > -1 && !store.state.direct) signOut()
    if (response.status === 500 && response.data.errorCode === 30) {
      return new Promise((resolve, reject) => {
        EventBus.$emit('modal', {
          modalType: 'confirm',
          confirmTitle: 'OBS!',
          confirmDescription: response.data.errorMessage,
          confirmButtonText: 'Fortsæt',
          cancelButtonText: 'Fortryd',
          onConfirm: () => {
            response.config.url = response.config.url + (response.config.url && response.config.url.indexOf('?') > -1 ? '&' : '?') + 'ignoreWarnings=true'
            axios(response.config).then(resolve, reject)
          }
        })
      })
    }
  }
  if (response.status >= 400 && response.status <= 500) {
    return Promise.reject(response)
  }
  return response
}, (error) => {
  return Promise.reject(error)
})

export default axios
