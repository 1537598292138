/* eslint-disable */
// @ts-nocheck
const lasso = {}
lasso.config = lasso.config || {}


lasso.state = {
	initializing: false,
	initialized: false,
	context: {features: []}
}
//Initialize the lasso SDK and get the context with which you'll communicate with Lasso.
var initializePromise = null
lasso.init = function (config) {
	if (initializePromise) return initializePromise
	initializePromise = new Promise(async (resolve, reject) => {
		if (window.location.host === 'localhost:8080' && window.self === window.top && process.env.NODE_ENV === 'development')
			await mockAppsIframe()
		var conf = config || {};
		lasso.sdk.communication.listen(lasso.sdk.communication.onMessage, lasso.sdk.helpers.getQueryStringParameter('url'), 0)
		lasso.sdk.getContext().then(context => {
			lasso.state.initialized = true
			lasso.state.initializing = false
			lasso.state.context = context
			lasso.config.useCache = conf.useCache ? true : false
			resolve(context)
			if (!conf.suppressReadySignal) {
				lasso.sdk.communication.signal('ready', null, { app: context.appConfig.uniqueName })
			}
			// listen to push messages for updated usersettings
            lasso.on('usersettingsupdate', ({message}) => {
                context.userSettings = message
            })
		})
	})
	return initializePromise
}

lasso.companies = {
	getPeople: (parameters, config) => lasso.sdk.get('company:people', parameters, config),
	getPlaces: (parameters, config) => lasso.sdk.get('company:places', parameters, config),
}
lasso.people = {}
lasso.lists = {
	add: (...args) => lasso.sdk.addToList(...args),
	remove: (...args) => lasso.sdk.removeFromList(...args),
	search: (entityType, query, config) => lasso.sdk.communication.request('search:' + entityType, {query}, config)
}

// initialize default requests for all entitytypes
var entityTypes = [{ single: 'company', plural: 'companies' }, { single: 'person', plural: 'people' }, { single: 'list', plural: 'lists' }]
var entityRequestsTypes = ['create', 'update', 'get', 'del', 'show', 'search']
entityTypes.forEach(entityType => entityRequestsTypes.forEach(requestType => {
	lasso[entityType.plural][requestType] = (parameters, config) => lasso.sdk[requestType](entityType.single, parameters, config)
}))

lasso.sdk = {}
lasso.sdk.create = (entityType, entity, config) => lasso.sdk.communication.request('create:' + entityType, { entity }, config)
lasso.sdk.update = (entityType, entity, config) => lasso.sdk.communication.request('update:' + entityType, { entity }, config)
lasso.sdk.get = (entityType, id, config) => lasso.sdk.communication.request('get:' + entityType, { id }, config)
lasso.sdk.del = (entityType, id, config) => lasso.sdk.communication.request('del:' + entityType, { id }, config)
lasso.sdk.show = (entityType, id, config) => lasso.sdk.communication.request('show:' + entityType, { id }, config)
lasso.sdk.search = (entityType, query, config) => lasso.sdk.communication.request('search:' + entityType, { query }, config)
lasso.sdk.addToList = (listId, listType, entityId, entityType, config) => lasso.sdk.communication.request('list:add', { listId, listType, entityId, entityType }, config)
lasso.sdk.removeFromList = (listId, listType, entityId, entityType, config) => lasso.sdk.communication.request('list:remove', { listId, listType, entityId, entityType }, config)
lasso.sdk.getContext = (config) => lasso.sdk.communication.request('config', null, config)
lasso.sdk.getUsers = (config) => lasso.sdk.get('users', null, config)
lasso.sdk.openApp = (uniqueName, config) => lasso.sdk.communication.request('navigate:app', { uniqueName: uniqueName }, config)
lasso.sdk.openDialog = (type, parameters, config) => lasso.sdk.communication.request('dialog:open', { type: type, parameters: parameters }, config)
lasso.sdk.closeDialog = (type, parameters, config) => lasso.sdk.communication.request('dialog:close', { type: type, parameters: parameters }, config)
lasso.sdk.trigger = (triggerName, parameters, config) => lasso.sdk.communication.request('trigger:' + triggerName, parameters, config)

/**
 * Tracks user events (e.g. clicking a certain button) in June for the purpose of analytics and statistics. Not to be confused with Plausible tracking used on lasso.dk.
 * @param { string } eventName - Loosely follows a convention of "[Module]: [Past tense event]" (e.g. "Prospecting: Schedule changed").
 * @param { Record<string, string>= } traits - (The equals sign means optional) Called properties in the June UI ('traits' is the portal name), additional contextual data to include with the event. Environment (staging vs prod), and user is added automatically.
 * @param { CommunicationConfig= } config - No custom config options for trackEvent, beyond whatever's inbuilt.
*/
lasso.sdk.trackEvent = (eventName, traits, config) => lasso.sdk.trigger('trackEvent', {event: eventName, traits}, config)
/**
 * Should be triggered when a user clicks a locked, premium feature. At the time of writing this opens an overlay where they can upgrade to a trial, but this is subject to change.
 * @param { string } source - What interaction the user had that triggered the premium feature, something "[X feature] clicked". Tracked as a trait in June.
 * @param { CommunicationConfig= } config - No custom config options for trackEvent, beyond whatever's inbuilt.
*/
lasso.sdk.premiumFeatureTrigger = (source, config) => lasso.sdk.trigger('premiumFeature', {source: source}, config)

lasso.sdk.findInProduct = (fromId, toContentProviderType, toEntityType, config) => new Promise((resolve, reject) => {
	if (!lasso.state.context.localLassoId) resolve(null)
	else {
		//product keeps it own copy of the lasso ID. Search there.
		var query = {entityTypes: [toEntityType], lassoId: fromId};
		lasso[toEntityType === 'company' ? 'companies' : 'people'].search(query, config).then(result => {
			if (result && result.length > 0) resolve(result[0])
			else resolve(null)
		}, reject)
	}
})

lasso.api = {}
lasso.api.get = (path, config) => lasso.sdk.communication.request('api:get', { path: path }, config)
lasso.api.post = (path, data, config) => lasso.sdk.communication.request('api:post', { path: path, data: data }, config)
lasso.api.put = (path, data, config) => lasso.sdk.communication.request('api:put', { path: path, data: data }, config)
lasso.api.del = (path, data, config) => lasso.sdk.communication.request('api:del', { path: path, data: data }, config)

lasso.signal = {}
lasso.signal.ready = () => lasso.sdk.communication.signal('ready', null, { app: lasso.state.context.appConfig.uniqueName })
lasso.signal.loading = message => lasso.sdk.communication.signal('loading', message, { app: lasso.state.context.appConfig.uniqueName })
lasso.signal.error = (message, error) => lasso.sdk.communication.signal('error', { message, error }, { app: lasso.state.context.appConfig.uniqueName })
lasso.signal.debug = (message) => lasso.sdk.communication.signal('debug', { message }, { app: lasso.state.context.appConfig.uniqueName })

lasso.sdk.helpers = lasso.sdk.helpers || {}
lasso.sdk.helpers.isSupported = (messageName) => {
	if (!lassoContext || !lassoContext.config || !lassoContext.config.bindings[messageName]) {
		alert('"' + messageName + '" is not supported')
	}
}

lasso.graph = lasso.graph || {}
lasso.graph.relate = (fromId, fromName, toId, toName) => {
	var qs = '?fromName=' + (encodeURIComponent(fromName) || '') + '&toName=' + (encodeURIComponent(toName) || '')
	return lasso.sdk.communication.request('api:post', { path: 'graph/' + fromId + '/neighbours/' + toId + qs, data: null, config: {} })
}
lasso.graph.unrelate = (fromId, toId) => {
	return lasso.sdk.communication.request('api:del', { path: 'graph/' + fromId + '/neighbours/' + toId, data: null, config: {} })
}
lasso.graph.deleteNode = (lassoId) => lasso.sdk.communication.request('api:del', { path: 'graph/' + lassoId, data: null, config: {} })
lasso.graph.getRelatedNode = (fromId, toContentProviderSignature, toEntityType) => lasso.api.get('graph/' + fromId + '/related/' + toContentProviderSignature + '/' + toEntityType)

lasso.sdk.helpers.getQueryStringParameter = (key) => {
	key = key.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]")
	var regex = new RegExp("[\\?&]" + key + "=([^&#]*)"),
		results = regex.exec(location.search)
	return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "))
}

lasso.sdk.helpers.getParentWindowUrl = (window) => {
	var l = window.parent.location
	return l.protocol + "//" + l.hostname + (l.port === "" || l.port === "80" || l.port === "443" ? '' : (':' + l.port))
}

// listen for push notifications
let pushHandlers = null
lasso.on = (msgType, callback) => {
	if (!pushHandlers) {
		pushHandlers = []
		// begin listening for push message
		lasso.sdk.communication.subscribe('push', msg => {
			let handlers = pushHandlers[msg.type]
			if (handlers) handlers.forEach(handler => handler(msg.message))
		})
	}

	let handlers = pushHandlers[msgType] = pushHandlers[msgType] || []
	handlers.push(callback)

	let off = () => {
		const index = handlers.indexOf(callback)
		if (index !== -1) handlers.splice(index, 1)
		off = () => {}
	}

	return off
}

lasso.platform = lasso.platform || {}
lasso.platform.preferPadding = lasso.sdk.helpers.getQueryStringParameter('padding') === 'true'

//Setup up cross-window communication channels
lasso.sdk.communication = lasso.sdk.communication || {}
lasso.sdk.communication.callbacks = {}

var pendingRequests = {}

/**
 * @typedef {{ {cache: true} & Record<string, any> }} CommunicationConfig
 */

/**
 * Sends message with data up through the data, usually being caught in the Lasso Portal/Platform, but also possibly used by external systems iframing Lasso.
 * @param { string } messageName
 * @param { Record<string,any> } data
 * @param { CommunicationConfig } config - TODO: Meta configuration. Not sure what it can do besides flagging cache.
 * @returns
 */
lasso.sdk.communication.request = (messageName, data, config) => {
	var message = { messageType: 'request', messageName: messageName, config: config || {}, id: "id" + Math.random().toString().substr(2) }
	if (lasso.config.useCache && message.config.cache === undefined) message.config.cache = true
	if (data) message.data = data
	var deferred = pendingRequests[message.id] = {}
	lasso.sdk.communication.send(message, window.parent, lasso.config.endpoints.parent)
	return new Promise((resolve, reject) => {
		deferred.resolve = resolve
		deferred.reject = reject
	})
}

lasso.sdk.communication.signal = (messageName, data, config) => {
	var message = { messageType: 'signal', 'messageName': messageName }
	if (data) message.data = data
	if (config) message.config = config
	lasso.sdk.communication.send(message, window.parent, lasso.config.endpoints.parent)
}

lasso.sdk.communication.respond = (message) => { lasso.sdk.communication.send(message, window.parent, lasso.config.endpoints.parent) }

lasso.sdk.communication.onMessage = (e) => {
	if (e.data === 'undefined') return
	if (postMessageObjectSupport) var message = e.data
	else var message = JSON.parse(e.data)
	if (message.messageName) {
		var callback = lasso.sdk.communication.callbacks[message.messageName]
		if (callback) callback(message.data)
		if (message.messageType === 'response' && message.request) {
			var pr = pendingRequests[message.request.id]
			if (pr) {
				if (message.status === 'success') { pr.resolve(message.data) }
				if (message.status === 'error') { pr.reject(message.error) }
			}
		}
	}
}

lasso.sdk.communication.subscribe = (name, callback) => {
	var callbacks = lasso.sdk.communication.callbacks
	if (!lasso.sdk.communication.isListening) lasso.sdk.communication.listen(lasso.sdk.communication.onMessage, lasso.config.endpoints.parent, 0)
	if (!callbacks[name]) callbacks[name] = callback
}

//Test if postMessage is allowed to send object in this browser. Otherwise a string will be sent instead.
var postMessageObjectSupport = true
try { window.postMessage({ toString: () => { postMessageObjectSupport = false; } }, "*"); } catch (e) { }
lasso.sdk.communication.send = (message, target, destination) => {
	if (postMessageObjectSupport) target.postMessage(message, destination.replace(/([^:]+:\/\/[^\/]+).*/, '$1'))
	else target.postMessage(JSON.stringify(message), destination.replace(/([^:]+:\/\/[^\/]+).*/, '$1'))
}

lasso.sdk.communication.listen = (messageHandler, endpoint, delay) => {
	var setMessageCallback,
		unsetMessageCallback,
		currentMsgCallback;

	if (window.postMessage) {
		var hasEventListener = window.addEventListener

		setMessageCallback = (callback) => hasEventListener ? window.addEventListener('message', callback, false) : window.attachEvent('onmessage', callback)
		unsetMessageCallback = (callback) => hasEventListener ? window.removeEventListener('message', callback, false) : window.detachEvent('onmessage', callback)

		lasso.sdk.communication.setReceiveHandler = (callback, sourceOrigin, delay) => {
			if (currentMsgCallback) {
				unsetMessageCallback(currentMsgCallback)
				currentMsgCallback = null
			}

			if (!callback) return false

			currentMsgCallback = setMessageCallback(function (e) {
				switch (Object.prototype.toString.call(sourceOrigin)) {
					case '[object String]':
						if (sourceOrigin !== e.origin) return false
						break
					case '[object Function]':
						if (sourceOrigin(e.origin)) return false
						break;
				}
				callback(e)
			})
		}
	}

	lasso.sdk.communication.setReceiveHandler(messageHandler, endpoint, delay)
	lasso.sdk.communication.isListening = true
	return this
};

lasso.config.endpoints = lasso.config.endpoints || {
	parent: lasso.sdk.helpers.getQueryStringParameter('url')
}

lasso.util = {
	isAdmin: () => {
		var lasso_util_isAdmin_userRoles = lasso.state.context.userRoles;
		return lasso_util_isAdmin_userRoles && lasso_util_isAdmin_userRoles.includes('Admin') || lasso_util_isAdmin_userRoles.includes('LassoAdmin');
	},
	isFreemium: () => lasso.state.context.solutionVersion === 'lasso_lite',
}


// If the app is acessed locally, not in an iframe, we mock the functionality of the usual parent window, the "apps" layer,
// by creating apps in an iframe, overwriting logic above and posting messages directly to it rather than to the parent.
const mockAppsIframe = async () => {
	// This 'if' ensures webpack doesn't build this code, although vite will still resolve the import further below
	if (process.env.NODE_ENV === 'development') {

		const iframeContainer = document.createElement("div")
		// https://stackoverflow.com/questions/8117761/how-can-i-make-an-iframe-resizable/60803488#60803488
		iframeContainer.style.resize = "both"
		iframeContainer.style.display = "flex"
		iframeContainer.style.overflow = "hidden"
		iframeContainer.style.width = "100%"
		iframeContainer.style.height = "56px" /* Height of the portal and enough to spot a SDK notification */
		iframeContainer.style.maxHeight = "90vh"
		iframeContainer.style.maxWidth = "100vw"

		// Real life URL example: https://dev.apps.lassox.com:5431/apps/?&menu=true&padding=true&url=https%3A%2F%2Fdev.portal.lassox.com%3A6543&productOrganizationId=&productOrganizationUrl=&productUserId=undefined&email=&frameId=12108321188192916&product=lasso&v=1.0.0&type=entityview&lassoId=CVR-1-31261430&initialApp=dashboard&entityType=company&entityId=CVR-1-31261430"
		const appsBaseUrl = "https://dev.apps.lassox.com:54311"
		const appsFrame = document.createElement("iframe")

		let contextEntityView
		// Mock the state that an app would normally get from the portal and apps. This file is gitignored so you can test different stuff and give your own token
		// The funky looking path is in order for both vite and vite-plugin-dynamic-import to not complain, and not resolve the file in build https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
		const mockFile = await import(`../sdk/${process.env.NODE_ENV === 'development' && 'lasso-app-context-mock'}.js`)
		if (!mockFile.contextEntityView) {
			console.info("No mock lasso-app-context-mock.js, this file must exist for the app SDK to work locally. Copy the .exaple file and fill in your own token.")
			return
		}
		contextEntityView = mockFile.contextEntityView

		const params = new URLSearchParams({
			// Very important, instructs apps to message it's parent iframe, rather than a non-existing child app iframe
			appIsParent: true,
			menu: false,
			padding: false,
			// Won't do much currently, but maybe in the future
			product: 'lasso',
			type: contextEntityView.appType,
			entityType: contextEntityView.entityType,
			// TODO: since entity is also in the mocked state, changing this alone won't do much. Load the entity separately?
			entityId: contextEntityView.entityId,
			lassoId: contextEntityView.lassoId,
		})

		const responseHandler = async (res) => {
			try {
				return await res.json()
			} catch (error) {
				if (res.status !== 204)
					console.warn('Error parsing JSON', error, res)
				return null
			}
		}

		// Mock SDK API calls
		const api = async (path, method, body, headers = null) => {
			console.debug('Mock API call', path, method, body, headers)
			const res = await fetch(path, { method, body: body ? body : undefined, credentials: 'include', headers: { ...headers, 'Content-Type': 'application/json', 'Accept': 'application/json' } })
			return responseHandler(res)
		}

		if (mockFile.fetchApiHost) {
			const loginResponse = await api(mockFile.fetchApiHost + 'login', "POST", { loginToken: contextEntityView.token }, 'include')
			lasso.api.get = (path) => api(mockFile.fetchApiHost + path, "GET", undefined, { 'Authorization': `Bearer ${loginResponse.token}` })
			// TODO: Fix up data, needs to be objectified differently
			lasso.api.post = (path, data) => api(mockFile.fetchApiHost + path, "POST", data, { 'Authorization': `Bearer ${loginResponse.token}` })
			lasso.api.put = (path, data) => api(mockFile.fetchApiHost + path, "PUT", data, { 'Authorization': `Bearer ${loginResponse.token}` })
			lasso.api.del = (path, data) => api(mockFile.fetchApiHost + path, "DELETE", data, { 'Authorization': `Bearer ${loginResponse.token}` })
		} else {
			const loginResponse = await api(contextEntityView.apiBaseAddress + 'login', "POST", JSON.stringify({ loginToken: contextEntityView.token }))
			lasso.api.get = (path) => api(contextEntityView.apiBaseAddress + path, "GET", undefined, { 'Authorization': `Bearer ${loginResponse.token}` })
			lasso.api.post = (path, data) => api(contextEntityView.apiBaseAddress + path, "POST", data, { 'Authorization': `Bearer ${loginResponse.token}` })
			lasso.api.put = (path, data) => api(contextEntityView.apiBaseAddress + path, "PUT", data, { 'Authorization': `Bearer ${loginResponse.token}` })
			lasso.api.del = (path, data) => api(contextEntityView.apiBaseAddress + path, "DELETE", data, { 'Authorization': `Bearer ${loginResponse.token}` })
		}

		const appsFullUrl = `${appsBaseUrl}/apps/?${params.toString()}`;

		appsFrame.setAttribute("src", appsFullUrl)
		appsFrame.setAttribute("id", "appsFrame")
		appsFrame.style.flexGrow = "1"
		iframeContainer.prepend(appsFrame)

		document.body.prepend(iframeContainer)

	const loadPromise = new Promise(resolve => { appsFrame.addEventListener('load', () => { resolve(true) }) })

	lasso.sdk.communication.listen(lasso.sdk.communication.onMessage, appsBaseUrl, 0)

	// Overwrite functions from earlier in this file

	lasso.sdk.getContext = async () => loadPromise.then(() => contextEntityView)

	lasso.sdk.communication.send = (message, target, destination) => {
		loadPromise.then(() => appsFrame.contentWindow.postMessage(message, "*"))
	}
}
}


export default lasso
